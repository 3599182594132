import { Visit } from "/src/models/visit";

import NoPhoto from "../../assets/image/no_photo.jpg";

import { isImage } from "../util/is-valid-url";

export class PhotoReview {
  constructor(data) {
    this.id = data.id; // number
    this.additional_info =
      data.ticket_contents && data.ticket_contents.length > 0 ? data.ticket_contents[0].additional_info : data.additional_info ? data.additional_info : null; // object

    this.picsell_info = data.picsell_info || {}; // obj 
    this.approval = data.approval || {}; // obj 
    this.ticket = data.ticket || ""; // string
    this.ticket_additional_info = data.ticket_additional_info || null; // object
    this.tip = data.tip || ""; // string
    // this.photo = data.url_photo && isValidUrl(data.url_photo) ? data.url_photo : ""; // string ${PHOTO_REVIEW_IMAGE_URL_BASE}
    this.photo = this.imgGroup(data);
    this.visit = data.visit && new Visit(data.visit);
    this.visit_id = data.visit_id; // string
  }

  imgGroup(data) {
    const arrPhotos = [];
    if (data.ticket_contents && data.ticket_contents.length > 0) {
      data.ticket_contents.forEach(item => {
        isImage(item.url_photo) ? arrPhotos.push(item.url_photo) : arrPhotos.push(NoPhoto);
      });

      return arrPhotos;
    } else {
      isImage(data.url_photo) ? arrPhotos.push(data.url_photo) : arrPhotos.push(NoPhoto);
      return arrPhotos;
    }
  }
}
