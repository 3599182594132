import React, { Component } from "react";
import { PageHeader, PageHeaderTitle, PageHeaderActions } from "/src/component/general/page-header";
import Modal from "/src/component/Modal";
import { PhotoReview } from "/src/models/photo-review";
import { call } from "/src/util/action";
import { dateFormat2 } from "/src/util/formatter";
import { filterUrl, validateReviewTask, dateChangeFormatFull, urlToIdPhotoAsync, maptoSelect2Objects, createLastDate } from "/src/util/util";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Alert from "react-s-alert";

import Loading from "../../component/Loading";
import Table from "./Table";
import Preview from "./Preview";
import { modelMap } from "../../config/config";
import { translatedComponents } from "../../config/const";
import { TRANSLATION_DOWNLOADER } from "../../config/lang";

import { getStore } from "../../util/redux/store";
import { translate } from "../../util/util";

import "./style.scss";

const me = getStore().getState().me;
const defaultTemplate = getStore().getState().defaultTemplate;

class PhotoReviewPage extends Component {
  constructor(props) {
    super(props);

    this.modeTypes = { view: "view", list: "list" };
    this.loadingRef = React.createRef();

    this.state = {
      mode: this.props.match.params?.id ? this.modeTypes.view : this.modeTypes.list,
      gridViewMode: "single",
      item: null, // object
      currentIndex: 0,
      data: [],
      count: 0,
      isModal: false,
      isModalAsq: false,
      isModalSingle: false,
      isResetPhotoItem: false,
      loading: false,
      isOpenFullList: false,
      loadedImagesList: {},
      pagination: {
        state: false,
        count: 0,
        sizePerPage: 50,
        pageAmount: 1,
        page: 1,
      },
      filter: {
        start: new Date(),
        end: new Date(),
        filters: [],
        newFilter: "",
        point__network: "",
        point__networks: [],
        point: "",
        points: [],
        user: "",
        users: [],
        brand: "",
        brands: [],
        tag: "",
        tags: [],
        product: "",
        products: [],
        subcategory: "",
        subcategories: [],
        comment: "",
        county: "",
        clients: "",
        isChange: false,
      },
      newTask: this.setInitTask(),
      newTaskLoading: false,
      prevY: 0,
      itemPost: [],
      lazyPagination: {
        page: 1,
        isNext: false,
      },
      scrollPosition: 0,
    };
  }

  async componentDidMount() {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    const { id } = this.props.match.params;
    const { hash } = this.props.location;

    if (id) {
      let mode = this.modeTypes.view;
      this.setState({ mode });
    }

    this.setStartDate();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { id } = this.props.match.params;
    const { item, data } = this.state;

    if (id && !item) {
      if (prevState.data !== data && data?.length) {
        this.setState({
          item: data?.find(review => review.id === +id),
        });
      }
    }
  }

  setInitTask = () => ({
    images: [],
    company: null,
    title: "",
    start: new Date(),
    end: new Date(new Date().setHours(23, 59, 59)),
    tags: [],
    components: [],
    priority: 2,
    template: "",
  });

  setStartDate = () => {
    let filter = this.state.filter;
    let start = new Date();
    start = start.setDate(start.getDate() - 14);
    filter.start = dateFormat2(start);
    this.setState({ filter }, () => {
      this.load();
    });
  };

  load = viewType => {
    let { pagination } = this.state;
    let url = "visits/photos_from_reports/?page=" + pagination.page;
    let groupUrl = viewType === "group" ? "&report_type=grouped" : ""; //custom url attr for get grouped photos
    let urlFilter = filterUrl("visits/photos_from_reports", this.state.filter) + groupUrl;
    if (urlFilter) url += urlFilter;

    this.setState({ loading: true });

    call({ method: "GET", url }, response => {
      if (!response.results) {
        console.log("error on getting filtered photo list");
        return;
      }

      // let count = response.count;
      let count = 9223372036854776000; // TODO hotfix after removing harcoded value from backend
      let sizePerPage = response.results.length < count && pagination.page === 1 ? response.results.length : pagination.sizePerPage;
      let isNext = response.next || false;
      let isPrev = response.previous || false;

      const newPagination = {
        state: true,
        pageAmount: Math.ceil(count / pagination.sizePerPage),
        count,
        page: pagination.page,
        sizePerPage,
        isNext,
        isPrev,
      };

      const data = response.results.map(photoData => new PhotoReview(photoData));
      this.setState({
        data,
        count,
        loading: false,
        pagination: newPagination,
        lazyPagination: {
          ...this.state.lazyPagination,
          isNext: !!response.results?.length,
        },
      });
    });
  };

  lazyLoad = () => {
    let { lazyPagination } = this.state;

    if (!lazyPagination.isNext) return;

    let url = "visits/photos_from_reports/?page=" + (lazyPagination.page + 1);
    let groupUrl = this.state.gridViewMode === "group" ? "&report_type=grouped" : "";
    let urlFilter = filterUrl("visits/photos_from_reports", this.state.filter) + groupUrl;
    if (urlFilter) url += urlFilter;

    this.setState({ loading: true });

    call({ method: "GET", url }, response => {
      if (!response.results) {
        console.log("error on getting filtered photo list");
        return;
      }

      let isNext = !!response.results.length || false;

      const newPagination = {
        page: response.results.length ? lazyPagination.page + 1 : lazyPagination.page,
        isNext,
      };

      const data = response.results.map(photoData => new PhotoReview(photoData));

      const accumulatedDataList = data?.length ? [...this.state.data, ...data] : this.state.data;

      this.setState({ data: accumulatedDataList, loading: false, lazyPagination: newPagination });
    });
  };

  submit = () => this.updatePaging(1, () => {}, this.state.gridViewMode);

  changeFilter = (filterEntity, item) => {
    let filter = this.state.filter;
    if (filterEntity === "start" || filterEntity === "end") {
      filter[filterEntity] = item;
    } else {
      filter[filterEntity] = item?.id;
    }

    if (
      [
        "filters",
        "users",
        "tag",
        "tags",
        "point__networks",
        "points",
        "brand",
        "brands",
        "product",
        "products",
        "subcategory",
        "subcategories",
        "comment",
        "client",
      ].includes(filterEntity)
    ) {
      filter[filterEntity] = item;
    }
    if (filterEntity === "filters" && item) {
      filter = this.loadingUserFilter(filter.filters);
    } else if (filterEntity === "filters" && !item) {
      this.resetFilter();
    }

    this.showHideAddFilterBlock(filter);
    this.setState({ filter });
  };

  showHideAddFilterBlock = filter => {
    filter.isChange = false;
    ["users", "tags", "point__networks", "points", "brands", "products", "subcategories", "comment"].forEach(i => {
      if (filter[i].length > 0) {
        filter.isChange = true;
      }
    });
  };

  resetFilter = () => {
    let filter = this.state.filter;

    Object.keys(filter).map(i => {
      filter[i] = [];

      if (i === "start" || i === "end") {
        filter[i] = new Date();
      }
    });

    this.setState({ filter }, () => {
      this.setStartDate();
      this.load();
    });
  };

  setApproveRejectState = (type, photoObj, quickView) => {
    let photoItem = quickView ? photoObj : this.state.item ? this.state.item : null;
    if (!photoItem.picsell_info.object_id || !photoItem.picsell_info.object_type) {
      Alert.error(translate(TRANSLATION_DOWNLOADER, "task_object_item_id_msg", this.props.me.language));
      return;
    }
    const approveRejectStatus = type === "ACCEPT" ? true : false;
    const approveRejectObject = {
      object_id: photoItem.picsell_info.object_id,
      object_type: photoItem.picsell_info.object_type, //('report', “pricemonitoringreport”, “answer”),
      is_approved: approveRejectStatus,
      comment: "",
    };

    call({ method: "POST", url: "approvals/", post: approveRejectObject }, response => {
      if (response) {
        approveRejectStatus
          ? Alert.success(translate(TRANSLATION_DOWNLOADER, "task_accept_msg", this.props.me.language))
          : Alert.success(translate(TRANSLATION_DOWNLOADER, "task_reject_msg", this.props.me.language));
        this.moveNext();
      } else {
        Alert.warning("Error: " + type + JSON.stringify(response));
        console.log(response);
      }
    });
  };

  acceptRejectAnswer = (type, photoObj, quickView) => {
    this.setApproveRejectState(type, photoObj, quickView);
  };

  moveNext = (DIRECTION = +1) => {
    let { currentIndex, data, count, pagination } = this.state;
    const el = document.querySelector(".photo-review-item-wrapper--active");
    const rect = el.getBoundingClientRect();

    if (count === 0) {
      this.setState({ mode: this.modeTypes.list });

      return;
    }

    if (DIRECTION === +1 && currentIndex === count - 1) {
      if (pagination.isNext) {
        this.updatePaging(pagination.page + 1);
      }
    }

    if (DIRECTION === -1 && currentIndex === 0) {
      if (pagination.isPrev) {
        this.updatePaging(pagination.page - 1, () => {
          let newItem = data[pagination.count - 1];
          this.setState({ currentIndex: pagination.count - 1, item: newItem });
        });
      }
    }

    let newIndex = 0;
    if (currentIndex + DIRECTION < count) {
      newIndex = currentIndex + DIRECTION;
    } else {
      newIndex = 0;
    }

    if (newIndex === -1 && DIRECTION === -1) newIndex = count - 1;

    if (data.length === newIndex + 1) {
      this.lazyLoad();
    }

    if (!data[newIndex]) {
      console.log("NEW ITEM NOT EXIST. NewIndex", newIndex);
      return;
    }
    // if (!data[newIndex] && DIRECTION === +1) {
    //   console.log("NEW ITEM NOT EXIST. NewIndex", newIndex);
    //   this.lazyLoad();
    //   // this.moveNext(+1);
    //
    //   return;
    // }

    let newItem = data[newIndex];

    this.setState({
      currentIndex: newIndex,
      item: newItem,
      newTask: this.setInitTask(),
      isModal: false,
      scrollPosition: rect.top - 150,
      isResetPhotoItem: !this.state.isResetPhotoItem,
    });
    //this.props.history.push(`/photo-review/${newItem.id}/view`);
  };

  openTask = (item, autoRunPassTask = false, quickItemPhotoIndex = null) => {
    let currentIndex = this.state.data?.indexOf(item);
    item.quickItemPhotoIndex = quickItemPhotoIndex || 0;
    this.setState(
      state => {
        return {
          item,
          currentIndex,
          scrollPosition: window.scrollY || 0,
          quickItemPhotoIndex,
        };
      },
      () => {
        this.setState({
          mode: this.modeTypes.list,
        });
        //this.props.history.push(`/photo-review/${item.id}/view`);
      }
    );

    this.modalOpenSinglePhoto();
    if (autoRunPassTask && quickItemPhotoIndex !== null) this.passTaskToModal(quickItemPhotoIndex, item);
  };

  back = () => {
    this.setState({ mode: this.modeTypes.list });
    this.props.history.push(`/photo-review/`);

    setTimeout(() => {
      window.scrollTo(0, this.state.scrollPosition);
    }, 100);
  };

  modalOpenSinglePhoto = () => {
    this.setState({ isModalSingle: true });
  };

  modalCloseSinglePhoto = () => {
    this.setState({ isModalSingle: false, isModal: false });
    this.back();
  };

  modalOpen = () => {
    this.setState({ isModal: true });
  };

  modalClose = () => {
    this.setState({ isModal: false });
  };

  modalFilterClose = () => {
    this.setState({ isModalAsq: false });
  };

  newTaskOnChange = (property, value) => {
    let item = this.state.newTask;
    item[property] = value;
    this.setState({ newTask: item });
  };

  passTaskToModal = (photoIndex, el) => {
    let { newTask, item } = this.state;
    if (el) item = el; // for quick open reject order from grid

    newTask.point = item?.visit?.point?.id || null;
    newTask.end = dateChangeFormatFull(newTask.start, 30); // TODO rewrite dateChangeFormat
    newTask.components = [
      { id: 3, name: translatedComponents["photo"], value: 3, label: translatedComponents["photo"] }, // HARDCODE as we dont know photo component object, but it can change
    ];

    item.photo[photoIndex] ? photoIndex : (photoIndex = 0);
    if (item.photo[photoIndex]) {
      newTask.images = [{ image: item.photo[photoIndex] }];
      newTask.imageFromReview = { image: item.photo[photoIndex] };
    }

    this.setState({ newTask, isModal: true });
  };

  onSaveEditorImage = (index, image) => {
    //index: number; image: base64 data image
    const { newTask } = this.state;

    if (!image) return;

    call({ method: "POST", url: "images/", post: { owner: this.props.me, image } }, image => {
      if (image?.id) {
        this.setState({ newTask: { ...newTask, images: [image] } });
      } else {
        console.log(image);
      }
    });
  };

  newTaskSubmit = async status => {
    let { newTask } = this.state;

    let itemPost = Object.assign({}, newTask);
    let queueLoadingTimer = 0;
    let queueLoadingStep = 8000;

    if (!this.state.item.picsell_info.object_id || !this.state.item.picsell_info.object_type) {
      Alert.error(translate(TRANSLATION_DOWNLOADER, "task_object_item_id_msg", this.props.me.language));
      return;
    }

    itemPost.components = newTask.components.map(i => i.id);
    itemPost.network = newTask?.network?.id || null;
    itemPost.end = moment(itemPost.end).format("yyyy-MM-DDT23:59:59");
    itemPost.created_by = newTask.created_by?.id || me?.id || null;
    itemPost.template = itemPost.template === "" ? defaultTemplate : itemPost.template;
    itemPost.visit_id = this.state.item?.visit_id || 0;
    const { validate, errors } = validateReviewTask("photo-review", itemPost);

    if (!validate) {
      this.setState({ errors });

      return;
    }

    if (itemPost.images.every(image => image.id)) {
      itemPost.images = newTask.images?.map(image => image.id);
      this.createNewTask(itemPost);
    } else {
      this.setState({
        newTaskLoading: true,
      });

      itemPost.images = newTask.images?.map((image, index) => {
        if (image.id) {
          itemPost.images = [image.id];
          this.createNewTask(itemPost);
          this.setState({
            itemPost,
            newTaskLoading: false,
            mode: this.modeTypes.list,
            isModal: false,
          });
          return image.id;
        } else {
          setTimeout(args => {
            urlToIdPhotoAsync(image.image, this.props.me, createdImg => {
              if (createdImg.id) {
                itemPost.images.push(createdImg.id);
                if (index === newTask.images.length - 1) {
                  itemPost.images = itemPost.images?.filter(item => !!item);
                  this.createNewTask(itemPost);
                  this.setState({
                    itemPost,
                    newTaskLoading: false,
                    mode: this.modeTypes.list,
                    isModal: false,
                  });
                }
              } else {
                console.error("image was not created");
              }
            });
          }, queueLoadingTimer);
          queueLoadingTimer += queueLoadingStep;
        }
      });
    }
  };

  createNewTask = data => {
    if (!data) return;

    call({ method: "POST", url: "tasks/", post: data }, response => {
      if (response?.id) {
        Alert.success(translate(TRANSLATION_DOWNLOADER, "newtask_success_msg", this.props.me.language));
        this.setState({ mode: this.modeTypes.list, isModal: false, errors: {} });
        this.acceptRejectAnswer("REJECT");

        this.setState({ newTask: this.setInitTask() });
      } else {
        Alert.warning("Error while saving item. Error: " + JSON.stringify(response));
        console.log(response);
      }
    });
  };

  loadingUserFilter = item => {
    let filter = JSON.parse(item?.filters_string);
    let lastDate = createLastDate();
    filter.filters = item;
    filter.start = lastDate.start;
    filter.end = lastDate.end;
    return filter;
  };

  addNewFilterName = name => {
    let filter = this.state.filter;
    filter.newFilter = name;
    this.setState({ filter });
  };

  validateFilterName = name => {
    if (name.length) {
      return name;
    } else {
      Alert.error(translate(TRANSLATION_DOWNLOADER, "valid_error_msg", this.props.me.language), { position: "top" });
      return false;
    }
  };

  removeUserFilterPopup = entity => {
    this.setState({ isModalAsq: true });
    return entity;
  };

  removeUserFilter = (entity = "filters") => {
    let filter = this.state.filter;
    let idFilter = filter.filters.id;
    let url = modelMap[entity] + idFilter + "/";
    this.setState({ isModalAsq: false });
    if (idFilter) {
      call({ method: "DELETE", url }, response => {
        if (response.ok === true) {
          Alert.success(translate(TRANSLATION_DOWNLOADER, "remove_success_msg", this.props.me.language), { position: "top" });
          this.updateUserFilter(entity, modelMap[entity]);
          this.resetFilter();
        }
      });
    } else {
      Alert.warning(translate(TRANSLATION_DOWNLOADER, "remove_confirmation_msg", this.props.me.language), { position: "top" });
    }
  };

  updateUserFilter = (entity, url) => {
    call({ method: "GET", url }, response => {
      response = response.results;
      response = maptoSelect2Objects(response, entity);
      this.setState({ list: response });
    });
  };

  removeUnnecesaryData = (filter, keys = []) => {
    let cloneFilter = { ...filter };
    keys.forEach(el => {
      delete cloneFilter[el];
    });
    return cloneFilter;
  };

  createNewFilter = (entity, filterName) => {
    let filterState = this.removeUnnecesaryData(this.state.filter, ["start", "end"]);
    let name = this.validateFilterName(filterName);
    let url = modelMap[entity];
    this.state.filter.newFilter = "";
    let item = {
      name,
      is_active: true,
      filters_string: JSON.stringify(filterState),
    };

    if (!name) return;
    call({ method: "POST", url, post: item }, response => {
      if (response?.id) {
        Alert.success("Фільтер зберeжено");
        this.setState({ errors: {} });
        this.updateUserFilter(entity, url);
        this.submit();
      } else {
        Alert.warning("Error while saving item. Error: " + JSON.stringify(response));
        console.log(response);
      }
    });
  };

  // PAGINATION
  updatePaging = (page, cb, viewType) => {
    let { pagination } = this.state;

    pagination.page = page;

    this.setState({ pagination: Object.assign({}, pagination) }, () => {
      this.load(viewType);
      if (cb) cb();
    });
  };

  //Group Photo View
  gridPhotoView = type => {
    this.setState({ gridViewMode: type }, () => {
      this.load(type);
    });
  };

  handleImageLoad = index => {
    this.setState(prevState => ({
      loadedImagesList: {
        ...prevState.loadedImagesList,
        [index]: true,
      },
    }));
  };
  handleImageCloseFullView = () => {
    this.setState({ isOpenFullList: false, loadedImagesList: {} });
  };

  openFullPhotoList = () => {
    this.setState({ isOpenFullList: true });
  };

  render() {
    const { mode, isModal, isModalAsq, isModalSingle, pagination, item, currentIndex, data, newTaskLoading, gridViewMode } = this.state;
    const previewNavigationData = {
      currentIndex: currentIndex || 0,
      lastIndex: data?.length || 0,
    };
    const loadingCSS = { height: "100px", margin: "30px", width: "" };

    const editBlockData = {
      item: this.state.newTask,
      type: "point",
      newTaskFromReviewPhoto: true,
      onChange: this.newTaskOnChange,
      onSaveEditorImage: this.onSaveEditorImage,
      submit: this.newTaskSubmit,
      setOpen: () => this.setState({ isOpen: true }),
      closeModal: this.modalClose,
      errors: this.state.errors,
      isImageEditable: true,
      newTaskLoading,
      isModal,
      currentPhotoIndex: this.state.quickItemPhotoIndex || 0,
    };

    return (
      <div className={`container-fluid photo-review-container`} onKeyDown={this.handleKeyDown}>
        {mode === this.modeTypes.view && (
          <PageHeader>
            <PageHeaderTitle>
              <span className="faIcon">
                <i className="fa fa-camera" />
              </span>
              {mode === this.modeTypes.list && <span>ФОТО Звіт</span>}
              {mode === this.modeTypes.view && <span>Перегляд</span>}
            </PageHeaderTitle>

            <PageHeaderActions>
              <div className="btn btn-primary" onClick={this.back}>
                <i className="fa fa-arrow-left" /> {translate(TRANSLATION_DOWNLOADER, "back", this.props.me.language)}
              </div>
            </PageHeaderActions>
          </PageHeader>
        )}

        <div className="box p-4">
          <div className={`${mode === this.modeTypes.list ? "h-show" : "h-hide"}`}>
            <Table
              {...this.state}
              lang={this.props.me?.language ? this.props.me?.language : "en"}
              changeFilter={this.changeFilter}
              resetFilter={this.resetFilter}
              submit={this.submit}
              openTask={this.openTask}
              pagination={pagination}
              updatePaging={this.lazyLoad}
              createNewFilter={this.createNewFilter}
              removeUserFilterPopup={this.removeUserFilterPopup}
              addNewFilterName={this.addNewFilterName}
              gridPhotoView={this.gridPhotoView}
              acceptRejectAnswer={this.acceptRejectAnswer}
            />
            <div ref={this.loadingRef} style={loadingCSS}>
              <span style={{ display: this.state.loading ? "block" : "none" }}>
                {translate(TRANSLATION_DOWNLOADER, "downloading", this.props.me?.language ? this.props.me?.language : "en")}...
              </span>
            </div>
          </div>

          {isModalSingle && (
            <Modal
              isShow={isModalSingle}
              isSingle={isModalSingle}
              isSupervised={!item.is_supervised}
              moveNext={this.moveNext}
              previewNavigationData={previewNavigationData}
              onClose={() => this.modalCloseSinglePhoto()}>
              <Preview
                item={item}
                acceptRejectAnswer={this.acceptRejectAnswer}
                passTaskToModal={this.passTaskToModal}
                moveNext={this.moveNext}
                previewNavigationData={previewNavigationData}
                editBlockData={editBlockData}
                isLoading={this.state.loading}
                gridViewMode={gridViewMode}
                isResetPhotoItem={this.state.isResetPhotoItem}
                openFullPhotoList={this.openFullPhotoList}
              />
            </Modal>
          )}

          {this.state.isOpenFullList && (
            <div className="full-photo-list" onClick={this.handleImageCloseFullView}>
              <i className="fa fa-times"></i>
              {item.photo.length > 0 &&
                item.photo.map((photoItem, index) => (
                  <div key={photoItem + index} className={`full-photo-item ${this.state.loadedImagesList[index] ? "" : "loading"}`}>
                    <Loading loading={this.state.loadedImagesList[index] ? false : true} />
                    <img
                      src={photoItem}
                      alt="images"
                      style={!this.state.loadedImagesList[index] ? { display: "none" } : {}}
                      onLoad={() => this.handleImageLoad(index)}
                    />
                  </div>
                ))}
            </div>
          )}

          {/*           {isModal && (
            <Modal isShow={isModal} onClose={() => this.modalClose()}>
              <div className="">
                <Loading loading={newTaskLoading} disP={true} type="spinner" />
                <TaskEdit
                  item={this.state.newTask}
                  type="point"
                  newTaskFromReviewPhoto
                  onChange={this.newTaskOnChange}
                  onSaveEditorImage={this.onSaveEditorImage}
                  submit={this.newTaskSubmit}
                  setOpen={() => this.setState({ isOpen: true })}
                  errors={this.state.errors}
                  isImageEditable={true}
                />
              </div>
            </Modal>
          )} */}
          {isModalAsq && (
            <Modal isShow={isModalAsq} customClass="asq-modal" onClose={() => this.modalFilterClose()}>
              <div className="asq-popup">
                <Loading loading={newTaskLoading} disP={true} type="spinner" />
                <p>{translate(TRANSLATION_DOWNLOADER, "confirmation_msg", this.props.me.language)}</p>
                <button className="asq-popup yes-asq" onClick={() => this.removeUserFilter()}>
                  {translate(TRANSLATION_DOWNLOADER, "yes", this.props.me.language)}
                </button>
                <button className="asq-popup no-asq" onClick={() => this.modalFilterClose()}>
                  {translate(TRANSLATION_DOWNLOADER, "cancel", this.props.me.language)}
                </button>
              </div>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({ me: state.me }))(PhotoReviewPage));
