import React from "react";
import Avatar from "/assets/image/avatar.jpg";
import moment from "moment";
import { Link } from "react-router-dom";

import { PRIORITY } from "./../config/const";
import AvatarCompany from "../../assets/image/company.png";
import Dots_nav_icon from "../../assets/image/dots-horizontal-icon.svg";
import Time_icon from "../../assets/image/time-icon.svg";
import { modelMap } from "../config/config";
import { MOVEMENTS, WEEK } from "../config/const";
import { MERCHANDISER_MAIN_PAGE, VISIT_PAGE } from "../config/lang";

import { call } from "../util/action";
import { getStore } from "../util/redux/store";
import { translate, translateDynamic } from "../util/util";

const me = getStore().getState().me;
export function IDFormatter(value) {
  return value;
}

export function IDFormatter2() {
  return "";
}

// TABLES
export function openVisit(id) {
  return (
    <a href={`/#/visit/${id}`} className="btn btn-sm btn-outline-primary" target="_blank">
      <i className="fa fa-external-link can_open" /> {translate(MERCHANDISER_MAIN_PAGE, "view_btn", me.language)}
    </a>
  );
}
export function openVisitRow(id, history) {
  history.push(`/visit/${id}`);
}

export function openSingleVisitTaskRow(id, history) {
  // history.push(`/task/${id}`);
  const url = `#/task/${id}`;
  window.open(url, "_blank");
}

export function openTask(id) {
  return (
    <a href={`/#/task/${id}`} className="btn btn-sm btn-outline-primary" target="_blank">
      <i className="fa fa-external-link can_open" />
    </a>
  );
}

export function openRoute(id) {
  return (
    <a href={`/#/route/${id}`} className="btn btn-sm btn-outline-primary">
      <i className="fa fa-external-link can_open" /> {translate(MERCHANDISER_MAIN_PAGE, "view_btn", me.language)}
    </a>
  );
}

export function openMerchandiser(id) {
  return (
    <a href={`/#/merchandiser/${id}`} className="btn btn-sm btn-outline-primary">
      <i className="fa fa-external-link can_open" /> {translate(MERCHANDISER_MAIN_PAGE, "view_btn", me.language)}
    </a>
  );
}

export function openPoint(id) {
  return (
    <a href={`/#/point/${id}`} className="btn btn-sm btn-outline-primary">
      <i className="fa fa-external-link can_open" /> {translate(MERCHANDISER_MAIN_PAGE, "view_btn", me.language)}
    </a>
  );
}

export function points(i, item) {
  return (
    <div>
      {item.points.map((i, idx) => (
        <div key={i.point.name + idx}>{i.point.name}</div>
      ))}
    </div>
  );
}

export function pointsCount(i, item) {
  return item.points.length;
}

export function point(i, item) {
  return item.point ? item.point.name + " - " + item.point.address : "";
}

export function visitPoint(i, item, cb) {
  if (!item.point) return;
  let date = "";

  if (item.start) {
    let newDate = new Date(item.start);
    date = moment(newDate).format("DD/MM/yyyy");
  }

  const author = `${item.user.first_name} ${item.user.last_name}`;

  return (
    <a
      href={`/#/visit/${item.id}`}
      onClick={() => {
        if (cb) cb(i);
      }}>
      <strong>{`${item.point.locality.name} - ${item.point.name} - ${item.point.address} - ${date}`}</strong>
      <p className="added-by">{translateDynamic(item.start, VISIT_PAGE, me.language, author)}</p>
    </a>
  );
}

export function visitOnePoint(i, item) {
  if (!item.point) return;
  let date = "";

  if (item.start) {
    let newDate = new Date(item.start);
    date = moment(newDate).format("DD.MM.yyyy HH:mm");
  }

  const author = `${item.user.first_name} ${item.user.last_name}`;
  const visitFullName = `${item.point.locality.name} - ${item.point.name} - ${item.point.address}`;
  const visitDate = date;

  return { name: visitFullName, date: visitDate, author: author };
}

export function visitItemStatus(i, item) {
  if (!item.collect_status_reports) return;

  const values = [
    item.collect_status_reports.completed_reports >= 0 ? item.collect_status_reports.completed_reports : 0,
    item.collect_status_reports.on_review_reports >= 0 ? item.collect_status_reports.on_review_reports : 0,
    item.collect_status_reports.not_completed_reports >= 0 ? item.collect_status_reports.not_completed_reports : 0,
  ];
  const total = values.reduce((sum, value) => sum + value, 0);

  return (
    <div className={`status-wrapper ${values[0] === 0 && values[1] === 0 && values[2] === 0 ? "full-empty-cell" : ""}`}>
      {values.map((value, index) => {
        const percentage = (value / total) * 100;
        return (
          <div
            key={index}
            className={index === 0 ? "approved-block" : index === 1 ? "pending-block" : "rejected-block"}
            style={{
              width: `${percentage}%`,
              overflow: `${percentage === 0 ? "hidden" : "visible"}`,
            }}>
            {value}
            <p className="status-label-text">
              {index === 0
                ? translate(VISIT_PAGE, "approved_label_popup", me.language)
                : index === 1
                ? translate(VISIT_PAGE, "pending_label_popup", me.language)
                : translate(VISIT_PAGE, "rejected_label_popup", me.language)}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export function visitItemProgress(i, item) {
  if (!item.collect_status_reports.progress_reports) return;
  return <div className="visit-progress">{item.collect_status_reports.progress_reports}</div>;
}

export function visitItemAction(i, item, cb) {
  if (!item.id) return;

  return (
    <div className="visit-item-action">
      <img src={Dots_nav_icon} alt="visit action nav" className="visit-action-icon" />
      <ul className="visit-action-nav">
        <li
          onClick={() => {
            cb(item, "approve");
          }}>
          Approve
        </li>
        <li
          onClick={() => {
            cb(item, "reject");
          }}>
          Reject
        </li>
      </ul>
    </div>
  );
}

export function visitItemMonitoringAction(i, item, cb) {
  return (
    <div className="visit-item-action">
      <img src={Dots_nav_icon} alt="visit action nav" className="visit-action-icon" />
      <ul className="visit-action-nav">
        <li
          onClick={() => {
            cb(item, "ACCEPT");
          }}>
          Approve
        </li>
        <li
          onClick={() => {
            cb(item, "REJECT");
          }}>
          Reject
        </li>
      </ul>
    </div>
  );
}

export function approveVisitStatus(i, item) {
  const className = item.is_approved ? "approved" : item.is_approved === false ? "rejected" : item.is_approved === null ? "pending" : "";
  return <p className={className}></p>;
}

export function taskTags(i, item) {
  return (
    <div>
      {item?.tags?.map((i, idx) => (
        <span key={i.name + idx} className="label rounded primary">
          {i.name}
        </span>
      ))}
    </div>
  );
}

export function taskComponents(i, item) {
  return (
    <span>
      {item.components &&
        item.components.map((i, idx) => (
          <span key={i.name + idx} className="label rounded primary">
            {i.name}
          </span>
        ))}
    </span>
  );
}

export function taskNegative(i, item) {
  return <span>{item.negative_answers_count}</span>;
}

export function taskComment(i, item) {
  return <span>{item.last_answer && item.last_answer.answer}</span>;
}

export function taskStatus(i, item) {
  return <span className={`label rounded ${item.status?.replace(" ", "-").toLowerCase()}`}>{item.status}</span>;
}

export function pointsTask(i, item) {
  return (
    <div>
      {item?.tags?.map((i, idx) => (
        <span key={i.name + idx} className="label rounded primary">
          {i.name}
        </span>
      ))}
    </div>
  );
}

export function locality(i, item) {
  return <span>{item.locality && item.locality.name && item.locality.name}</span>;
}

export function pointState(i, item) {
  return <span className={"label rounded " + (item.is_active ? "primary" : "grey")}>{item.is_active ? "Active" : "Not Active"}</span>;
}

export function imageCount(i, item) {
  return item && item.image_count;
}

export function tagCount(i, item) {
  return item?.tags?.length;
}

export function day(i, item) {
  return <span className="dayLabel"> {item && WEEK[item.day_of_the_week]}</span>;
}

export function movement(i, item) {
  return <span> {item && MOVEMENTS[item.movement]}</span>;
}

export function userName(i, item) {
  return (
    <span>
      <img className="w-32 img-circle avatar-crop" src={item.photo ? item.photo : Avatar} /> {item && item.first_name} {item && item.last_name}
    </span>
  );
}

export function userState(i, item) {
  return <span className={"label rounded " + (item.is_active ? "primary" : "grey")}>{item.is_active ? "Active" : "Not Active"}</span>;
}

export function supervisorName(i, item, cb) {
  if (item.point && item.point.responsible_supervisors.length) {
    let user = item.point.responsible_supervisors[0];
    return (
      <a
        href={`/#/visit/${item.id}`}
        onClick={() => {
          if (cb) cb(item.id);
        }}>
        <span>
          <img className="w-32 img-circle avatar-crop" src={user.photo ? user.photo : Avatar} /> {user.first_name} {user.last_name}
        </span>
      </a>
    );
  }
}
export function createdByInfo(i, item) {
  const user = item.created_by;
  if (user) {
    return (
      <span>
        <img className="w-32 img-circle avatar-crop" src={user.photo ? user.photo : Avatar} /> {user.first_name} {user.last_name}
      </span>
    );
  }
}

export function merchandiserName(i, item) {
  const user = item.point?.responsible_merchandiser;
  if (user) {
    return (
      <span>
        <img className="w-32 img-circle avatar-crop" src={user.photo || Avatar} /> {user.first_name} {user.last_name}
      </span>
    );
  }
}

export function authorWay(i, item, cb) {
  if (!item.user) return;

  return (
    <a
      href={`/#/visit/${item.id}`}
      onClick={() => {
        if (cb) cb(item.id);
      }}>
      {item.user.first_name + " " + item.user.last_name}
    </a>
  );
}

export function merchandiserPhone(i, item) {
  if (item.point && item.point.responsible_merchandiser) {
    let user = item.point.responsible_merchandiser;
    return <span>{user.phone_number} </span>;
  }
}

export function networkName(i, item) {
  return (
    item.network && (
      <span>
        <img className="w-32 img-circle avatar-crop" src={item.network.image ? item.network.image : AvatarCompany} /> {item.network.name}
      </span>
    )
  );
}

export function networkTask(i, item) {
  return item.network && item.network.name && item.network.name;
}

export function getNetworkData(id) {
  let networkName = "";
  call({ method: "GET", url: modelMap["network"] }, response => {
    networkName = response?.find(item => item.id === id)?.name;
  });

  return networkName || "—";
}

// DATES
export function userDateJoined(i, item) {
  if (!item.date_joined) return;
  let date = new Date(item.date_joined);
  return moment(date).format("DD/MM/yyyy");
}

export function userDateTillNewbie(i, item) {
  if (!item.date_till_newbie) return;
  let date = new Date(item.date_till_newbie);
  return moment(date).format("DD/MM/yyyy");
}

export function userDateDeactivated(i, item) {
  if (!item.date_deactivated) return;
  let date = new Date(item.date_deactivated);
  return moment(date).format("DD/MM/yyyy");
}

export function timeSpent(i, item) {
  // console.log(item);

  if (!item.time_spent) return;

  const seconds = item.time_spent;
  let minutes = Math.floor(seconds / 60);
  let remainingSeconds = Math.floor(seconds % 60);
  let milliseconds = Math.floor((seconds % 1) * 1000);

  return `<div class="time-block"><img src="${Time_icon}" class="time-icon" />${minutes}m ${remainingSeconds}s ${milliseconds}ms</div>`;
}

export function timeSpentSingleVisit(item) {
  if (!item?.time_spent) return;

  const seconds = item.time_spent;
  let minutes = Math.floor(seconds / 60);
  if (minutes > 60) {
    minutes = Math.floor(minutes / 60);
    return `${minutes}h`;
  } else {
    return `${minutes}m`;
  }
}

export function approvedBy(i, item, cb) {
  if (!item.approved_by) return;
  return (
    <a
      href={`/#/visit/${item.id}`}
      onClick={() => {
        if (cb) cb(item.id);
      }}>
      <span className="approved-by">
        <img className="w-32 img-circle avatar-crop" src={item.approved_by.photo ? item.approved_by.photo : Avatar} /> {item.approved_by.first_name}
        {item.approved_by.last_name}
      </span>
    </a>
  );
}

export function dateStart(i, item) {
  if (!item.start) return;
  let date = new Date(item.start);
  return moment(date).format("DD/MM/yyyy");
}

export function dateEnd(i, item) {
  if (!item.end) return;
  let date = new Date(item.end);
  return moment(date).format("DD/MM/yyyy");
}

export function dateCreated(i, item) {
  if (!item.end) return;
  let date = new Date(item.created);
  return moment(date).format("DD/MM/yyyy");
}

export function dateTimeCreated(item) {
  let date = new Date(item.created);
  return moment(date).format("DD/MM/yyyy hh:mm:ss");
}

// REVIEW
export function reviewTaskSalePriceMonitoring(i, item) {
  if (item.additional_info.sale_price && item.additional_info.sale_price !== "None") {
    let sale_price = item.additional_info.sale_price;

    return <p>{sale_price}</p>;
  }
}
export function reviewTaskPriceMonitoring(i, item) {
  if (item.additional_info.price && item.additional_info.price !== "None") {
    let price = item.additional_info.price;

    return <p>{price}</p>;
  }
}

export function reviewMerchandiserNamePhone(i, item) {
  if (item?.visit?.user) {
    let user = item.visit.user;

    return (
      <span>
        <img className="w-32 img-circle avatar-crop" src={user.photo ? user.photo : Avatar} />
        <span>
          {user.first_name} {user.last_name} {user.phone && <span>,{user.phone}</span>}
        </span>
      </span>
    );
  }
}

export function getApproveRejectInfo(type, item) {
  if (!item.approved_by) return;

  if (type === "name") {
    return (
      <span>
        <span>
          {item.approved_by.first_name} {item.approved_by.last_name}
        </span>
      </span>
    );
  }
}

export function reviewTaskSupervisor(i, item) {
  if (item.visit && item.visit.point && item.visit.point.responsible_supervisors) {
    let user = item.visit.point.responsible_supervisors[0];

    return (
      <span>
        <img className="w-32 img-circle avatar-crop" src={user?.photo ? user?.photo : Avatar} />
        <span>
          {user?.first_name} {user?.last_name}
        </span>
        {(user?.phone || user?.phone_number) && <span> ,{user?.phone || user?.phone_number}</span>}
      </span>
    );
  }
}

export function reviewCreatedByTaskSupervisor(item) {
  if (item?.task && item?.task.created_by) {
    let user = item?.task.created_by;

    return (
      <span>
        <img className="w-32 img-circle avatar-crop" src={user?.photo ? user?.photo : Avatar} />
        <span>
          {user?.first_name} {user?.last_name}
        </span>
        {(user?.phone || user?.phone_number) && <span> ,{user?.phone || user?.phone_number}</span>}
      </span>
    );
  }
}

export function reviewTaskNework(i, item) {
  item.network = item.task.network;

  return (
    item.network && (
      <span>
        <img className="w-32 img-circle avatar-crop" src={item.network.image ? item.network.image : AvatarCompany} /> {item.network.name}
      </span>
    )
  );
}
export function reviewTaskVisitPoint(i, item) {
  if (item?.visit?.point) {
    let point = item.visit.point;

    return (
      <Link to={"/point/" + point.id} target="_blank">
        {point.name}
      </Link>
    );
  }
  return null;
}

export function reviewTaskPoint(i, item) {
  if (item?.task?.point) {
    let point = item.task.point;

    return (
      <Link to={"/point/" + point.id} target="_blank">
        {point.name}
      </Link>
    );
  }
  return null;
}

export function taskPoint(item) {
  if (item.point) {
    let point = item.point;
    return (
      <Link to={"/point/" + point.id} target="_blank">
        {point.name}
      </Link>
    );
  }
}

export function getTaskNetwork(item) {
  if (item.point) {
    let point = item.point;
    return (
      <Link to={"/point/" + point.id} target="_blank">
        {point.name}
      </Link>
    );
  }
}

export function reviewTaskPriority(i, item) {
  return <div>{PRIORITY[item.task.priority] ? PRIORITY[item.task.priority] : ""}</div>;
}

export function dateFormat(date) {
  if (!date) return;
  date = new Date(date);
  return moment(date).format("DD/MM/yyyy HH:mm");
}

export function dateFormat2(date) {
  date = new Date(date);
  return moment(date).format("yyyy-MM-DD");
}

export function dateFormatFullZero(date) {
  date = new Date(date);
  return moment(date).format("yyyy-MM-DDTHH:mm:ss");
}

export function dateFormatFull(date) {
  date = new Date(date);
  return moment(date).format("yyyy-MM-DDT23:59:59");
}
