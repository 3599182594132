import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import Alert from "react-s-alert";

import ImageCanvasEditor from "../../component/image-canvas-editor/image-canvas-editor";
import Loading from "../../component/Loading";
import PhotoTaskSlider from "../../component/PhotoTaskSlider";
import TaskPriority from "../../component/TaskPriority";
import { API_URL_LOGIN } from "../../config/config";
import { PHOTO_REVIEW } from "../../config/lang";
import { TaskEdit } from "../task/Edit";

import { call } from "../../util/action";
import {
  dateFormat,
  reviewTaskSalePriceMonitoring,
  reviewTaskPriceMonitoring,
  reviewMerchandiserNamePhone,
  reviewTaskVisitPoint,
  reviewTaskSupervisor,
  getApproveRejectInfo,
  reviewCreatedByTaskSupervisor,
} from "../../util/formatter";
import { getStore } from "../../util/redux/store";
import { translate } from "../../util/util";

import "./style.scss";

const me = getStore().getState().me;
class Preview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
      apiAnswerData: null,
      answerLoading: false,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    document.addEventListener("keydown", this.handleKeyDown, false);
    const { item } = this.props;

    if (item.picsell_info?.object_type === "answer") {
      this.fetchAnswerData();
    }
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  componentDidUpdate(prevProps) {
    const { item } = this.props;

    if (item.picsell_info?.object_type === "answer" && prevProps.item.picsell_info?.object_id !== item.picsell_info?.object_id) {
      this.setState({ apiAnswerData: null });
      this.fetchAnswerData();
    }

    if (this.props.isResetPhotoItem !== prevProps.isResetPhotoItem && this.props.gridViewMode === "group") {
      this.setState({ photoIndex: 0 });
    }
  }

  handleKeyDown = e => {
    let { acceptRejectAnswer, passTaskToModal, moveNext } = this.props;

    switch (e.code) {
      case "ArrowLeft":
        moveNext(-1);
        break;
      case "ArrowRight":
        moveNext(+1);
        break;
      case "ArrowUp":
        acceptRejectAnswer("ACCEPT");
        break;
      case "ArrowDown":
        passTaskToModal();
        break;
    }
  };

  deleteImage = idx => {
    let images = this.props.editBlockData.item.images;
    let id = images[idx].id;
    // delete images[idx];
    this.props.editBlockData.onChange("images", []);

    call({ method: "DELETE", url: "images/" + id + "/", body: {} }, () => {
      Alert.success("Image successfully deleted", { position: "top" });
    });
  };

  handleDownlodDetailsPhoto = async e => {
    e.preventDefault();
    e.stopPropagation();

    const image = await fetch(`${e.currentTarget?.href}?chunk=${Date.now()}`); // expected <a href> link  or this.props.item.photo
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    // const imageName = imageURL.substring(imageURL.lastIndexOf("/") + 1);
    const imageName = this.getPhotoName();
    const link = document.createElement("a");

    link.href = imageURL;
    link.download = `${imageName}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  getPhotoName = () => {
    const dateString = dateFormat(this.props.item.visit?.start);
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const [hours, minutes] = timePart.split(":");
    const pointName = this.props.item.visit?.point?.name;
    const pointNameString = pointName.replace(/\s+/g, "_");

    const formattedDate = `${day}_${month}_${year}_${hours}_${minutes}_${pointNameString}`;

    return formattedDate;
  };

  isMonitoringData = () => {
    const item = this.props.item;
    return reviewTaskPriceMonitoring("", item) || reviewTaskSalePriceMonitoring("", item) ? true : false;
  };

  activePhotoIndex = (index = 0) => {
    this.setState({ photoIndex: index });
    return index;
  };

  //check if value is function or object
  isFunctionOrObject = value => {
    return value !== null && typeof value === "function";
  };

  fetchAnswerData = async () => {
    this.setState({ answerLoading: true });

    let { item } = this.props;
    let answerId = item.picsell_info?.object_id;

    call({ method: "GET", url: `answers/${answerId}/` }, data => {
      this.setState({ apiAnswerData: data, answerLoading: false });
    });
  };

  render() {
    let { item, acceptRejectAnswer, passTaskToModal, editBlockData, isLoading, gridViewMode, openFullPhotoList } = this.props;
    const { apiAnswerData, answerLoading } = this.state;
    editBlockData = this.isFunctionOrObject(editBlockData) ? editBlockData() : editBlockData;
    if (!item) {
      return null;
    }

    const tags = Array.isArray(item?.additional_info?.tags)
      ? item?.additional_info?.tags?.map(i => (
          <span key={i} className="label rounded">
            {i}
          </span>
        ))
      : typeof item?.additional_info?.tags === "string"
      ? item?.additional_info?.tags
      : "";

    let siteUrl = API_URL_LOGIN;
    siteUrl = siteUrl.replace("/login/", "");
    return (
      <div className="item taskBlock" data-photo-current-index={this.state.photoIndex}>
        <Loading loading={isLoading} disP={true} type="spinner" />
        <div className="col-md-5 pr-2 photoContainer photo-carousel">
          <div className={`original-photo original-photos-wrapper ${editBlockData.isModal > 0 ? "hidden" : ""}`}>
            
            {gridViewMode === "single" ? (
              <div className="photo-review-list">
                {/* {console.log(item, "ITEM")} */}
                {item.photo ? (
                  <img src={item.photo[item.quickItemPhotoIndex] ? item.photo[item.quickItemPhotoIndex] : item.photo[0]} className="previewImage" />
                ) : item.url_photo ? (
                  <img src={item.url_photo} className="previewImage" />
                ) : (
                  <h5>Photo Not found</h5>
                )}
              </div>
            ) : (
              <PhotoTaskSlider
                activePhotoIndex={this.activePhotoIndex}
                quickItemPhotoIndex={item.quickItemPhotoIndex}
                photos={item.photo}
                isDeepArray={true}
                clickCallback={index => {
                  openFullPhotoList(index);
                }}
                viewMode={gridViewMode}
              />
            )}
            <div className="previewPhotoNav">
              {item.photo ? (
                item.photo.map((photoItem, index) => {
                  return (
                    <div key={photoItem + index} className={`openPhoto ${index === this.state.photoIndex ? "active" : ""}`}>
                      <a href={photoItem} target="_blank">
                        <i className="fa fa-arrows-alt" />
                      </a>
                      <a href={photoItem} download className="ml-1" onClick={this.handleDownlodDetailsPhoto}>
                        <i className="fa fa-cloud-download" />
                      </a>
                    </div>
                  );
                })
              ) : item.url_photo ? (
                <div className="openPhoto active">
                  <a href={item.url_photo} target="_blank">
                    <i className="fa fa-arrows-alt" />
                  </a>
                  <a href={item.url_photo} download className="ml-1" onClick={this.handleDownlodDetailsPhoto}>
                    <i className="fa fa-cloud-download" />
                  </a>
                </div>
              ) : null}
            </div>
            <div className="details-buttons-container">
              <div className="allowed-button">
                <button
                  className={`btn btn-primary acceptBtn ${item.approval?.is_approved === true ? "is-approval" : ""}`}
                  onClick={() => {
                    acceptRejectAnswer("ACCEPT");
                  }}
                  title={translate(PHOTO_REVIEW, "approv_btn", me.language)}>
                  <i className="fa fa-check-circle" />
                </button>
              </div>
              <div className="cancel-button">
                <button
                  className={`btn btn-primary rejectBtn ${item.approval?.is_approved === false ? "not-approval" : ""}`}
                  onClick={() => {
                    passTaskToModal(this.state.photoIndex);
                  }}
                  title={translate(PHOTO_REVIEW, "reject_btn", me.language)}>
                  <i className="fa fa-times-circle" />
                </button>
              </div>
            </div>
          </div>
          {editBlockData.item?.images?.length > 0 && editBlockData.isModal ? (
            <div className="taskImagesEdit">
              {editBlockData.item?.images?.map((image, idx) => (
                <div key={idx} className="d-flex">
                  <img src={image.image} onClick={editBlockData.setOpen} />
                  <div className="previewPhotoNav">
                    {editBlockData.isImageEditable && <ImageCanvasEditor src={image.image} id={idx} onSaveImage={editBlockData.onSaveEditorImage} />}
                    {/*   <button type="button" className="btn btn-primary btn-sm white" onClick={this.onModalToggle}>
                      <i className="fa fa-edit" />
                    </button> */}
                    <button type="button" onClick={() => this.deleteImage(idx)}>
                      <i className="fa fa-minus-square-o" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          
        </div>
        <div className="col-md-7 py-4">
          <div className={`photo-review-details text-left ${editBlockData.isModal ? "hidden" : ""}`}>
            <div className="photo-review-header">
              <div className="form-group">
                <img src={`${siteUrl}${item.visit?.point?.image}`} className="point-logo" alt={item.visit?.point?.locality?.name} />
                <div className="point-location">
                  {reviewTaskVisitPoint("", item)}
                  <p>{item.visit?.point?.locality?.name}</p>
                </div>
              </div>
              <div className="form-group tags-group">
                <p>{tags}</p>
              </div>
            </div>
            <div className="photo-review-content">
              <div className="form-group">
                <label>{translate(PHOTO_REVIEW, "merchendiser", me.language)}</label>
                {reviewMerchandiserNamePhone("", item)}
              </div>

              <div className="form-group">
                <label>{translate(PHOTO_REVIEW, "responsible_supervisor", me.language)}</label>
                {reviewTaskSupervisor("", item)}
              </div>

              {item.approval?.approved_at ? (
                <>
                  <div className="form-group">
                    <label>{translate(PHOTO_REVIEW, "approve_reject_label_block", me.language)}</label>
                    <div>
                      <div className={`status ${item.approval.is_approved === true ? "approved" : item.approval.is_approved === false ? "rejected" : ""}`}>
                        {item.approval.is_approved === true
                          ? translate(PHOTO_REVIEW, "approved", me.language)
                          : item.approval.is_approved === false
                          ? translate(PHOTO_REVIEW, "rejected", me.language)
                          : ""}
                      </div>
                      {getApproveRejectInfo("name", item.approval)}
                      {" ("}
                      {dateFormat(item.approval.approved_at)}
                      {")"}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="form-group">
                <label>{translate(PHOTO_REVIEW, "linkedVisit", me.language)}</label>
                <a href={`${window.location.origin}#/visit/${item.visit_id}`} className="styled-link" target="_blank" rel="noopener noreferrer">
                  <span className="icon">🔗</span>
                  <span className="link-id">{item.visit?.point?.locality?.name}</span>
                  <span className="link-title">{item.visit?.point?.name}</span>
                </a>
              </div>

              <div className="form-group">
                <label>{translate(PHOTO_REVIEW, "start_visit", me.language)}</label>
                <p>{dateFormat(item.visit?.start)}</p>
              </div>

              <div className="form-group">
                <label>{translate(PHOTO_REVIEW, "end_visit", me.language)}</label>
                <p>{dateFormat(item.visit?.end)}</p>
              </div>

              <div className="form-group">
                <label>{translate(PHOTO_REVIEW, "comment", me.language)}</label>
                <p>{item?.additional_info?.comment}</p>
              </div>
            </div>

            {this.isMonitoringData() ? (
              <>
                <div className="photo-review-header monitoring-header">
                  <div className="form-group">
                    <div className="point-location">
                      <p className="title-block">{translate(PHOTO_REVIEW, "price_monitoring", me.language)}</p>
                    </div>
                  </div>
                </div>
                <div className="photo-review-content">
                  {reviewTaskPriceMonitoring("", item) ? (
                    <div className="form-group">
                      <label>{translate(PHOTO_REVIEW, "price", me.language)}</label>
                      {reviewTaskPriceMonitoring("", item)}
                    </div>
                  ) : null}
                  {reviewTaskSalePriceMonitoring("", item) ? (
                    <div className="form-group">
                      <label>{translate(PHOTO_REVIEW, "sales_price", me.language)}</label>
                      {reviewTaskSalePriceMonitoring("", item)}
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
            {item.picsell_info?.object_type === "answer" && !answerLoading && apiAnswerData !== null && apiAnswerData?.task != null ? (
              <>
                <div className="photo-review-header monitoring-header">
                  <p className="title-block">{translate(PHOTO_REVIEW, "answer", me.language)}</p>
                </div>
                <div className="photo-review-content">
                  <div className="form-group">
                    <label>{translate(PHOTO_REVIEW, "linkedTasks", me.language)}</label>
                    <a href={`${window.location.origin}#/task/${apiAnswerData?.task.id}`} className="styled-link" target="_blank" rel="noopener noreferrer">
                      <span className="icon">🔗</span>
                      <span className="link-id">{apiAnswerData?.task.id}</span>
                      <span className="link-title">{apiAnswerData?.task.title}</span>
                    </a>
                  </div>
                  <div className="form-group">
                    <label>{translate(PHOTO_REVIEW, "description", me.language)}</label>
                    <p> {apiAnswerData?.answer} </p>
                  </div>
                  <div className="form-group">
                    <label>{translate(PHOTO_REVIEW, "created_by", me.language)}</label>
                    {reviewCreatedByTaskSupervisor(apiAnswerData)}
                  </div>
                  {apiAnswerData?.boolean_answer !== null && apiAnswerData?.boolean_answer !== undefined && (
                    <div className="form-group">
                      <label>{translate(PHOTO_REVIEW, "answer", me.language)}</label>
                      <p>{apiAnswerData.boolean_answer ? translate(PHOTO_REVIEW, "yes", me.language) : translate(PHOTO_REVIEW, "no", me.language)}</p>
                    </div>
                  )}
                  <div className="form-group">
                    <label>{translate(PHOTO_REVIEW, "priority", me.language)}</label>
                    <TaskPriority priority={apiAnswerData?.task.priority} />
                  </div>
                  <div className="form-group">
                    <label>{translate(PHOTO_REVIEW, "status", me.language)}</label>
                    <p className="orange-yellow-background">{apiAnswerData?.task.status}</p>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          {editBlockData.isModal ? (
            <div className="text-left new-task-wrapper">
              <Loading loading={editBlockData.newTaskLoading} disP={true} type="spinner" />
              <TaskEdit {...editBlockData} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Preview;
