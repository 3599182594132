import React, { useEffect, useRef, useState } from "react";

function PhotoTaskSlider({ photos, activePhotoIndex, quickItemPhotoIndex, clickCallback, isDeepArray, loadPhotoReviewFn = () => {}, viewMode }) {
  const [slideIndex, setSlideIndex] = useState(1);
  const [loadedImages, setLoadedImages] = useState([]);
  const quickOpenTrigger = useRef(true);

  const nextButtonHandler = () => {
    if (slideIndex < photos.length) {
      setSlideIndex(prev => prev + 1);
      if (activePhotoIndex instanceof Object) activePhotoIndex(slideIndex);
    }
  };

  const prevButtonHandler = () => {
    if (slideIndex > 1) {
      setSlideIndex(prev => prev - 1);
      if (activePhotoIndex instanceof Object) activePhotoIndex(slideIndex - 2);
    }
  };

  useEffect(() => {
    quickItemPhotoIndex > 0 && quickOpenTrigger.current ? (setSlideIndex(quickItemPhotoIndex + 1), (quickOpenTrigger.current = false)) : setSlideIndex(1);
  }, [photos]);

  return (
    <>
      {viewMode === "group" ? (
        <div className="dot-list">
          {photos?.map((el, index) => (
            <span key={index} className={`dot ${index === slideIndex - 1 ? "active" : ""}`}></span>
          ))}
        </div>
      ) : null}
      <div className="photo-list" data-photo-current-index={slideIndex - 1}>
        {photos?.map((photo, index) => {
          return (
            <img
              key={index}
              src={isDeepArray ? photo : photo.photo_url}
              data-index={index}
              onClick={() => clickCallback(slideIndex - 1)}
              style={{ transform: `translateX(-${index * 100}%)` }}
              onLoad={() => {
                loadPhotoReviewFn();
              }}
              className={`previewImage img-placeholder ${index === slideIndex - 1 ? "active" : ""} ${photos?.length === 1 ? "photo-visible" : ""}`}
            />
          );
        })}
      </div>
      {photos?.length > 0 && viewMode === "group" ? (
        <div className="carousel-navigations">
          <div className="side-nav side-nav-prev" onClick={prevButtonHandler}>
            <button className="slide-arrow">
              <i className="fa fa-chevron-circle-left"></i>
            </button>
          </div>
          <div className="side-nav side-nav-next" onClick={nextButtonHandler}>
            <button className="slide-arrow">
              <i className="fa fa-chevron-circle-right"></i>
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default PhotoTaskSlider;
