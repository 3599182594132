import React, { useEffect } from "react";
import moment from "moment";
import { TableHeaderColumn, BootstrapTable } from "react-bootstrap-table";
import { useHistory } from "react-router-dom";

import Loading from "../../../component/Loading";
import { dataTableConfig } from "../../../config/config";
import { VISIT_PAGE, TRANSLATION_DOWNLOADER } from "../../../config/lang";

import { call } from "../../../util/action";
import CreateMsgPopup from "../../../util/CreateMsgPopup";
import { openSingleVisitTaskRow } from "../../../util/formatter";
import { getStore } from "../../../util/redux/store";
import { translate } from "../../../util/util";

const me = getStore().getState().me;
function Tasks({ data }) {
  const [state, setState] = React.useState({ visits: "", loading: false, isSelected: false });
  const [checkedRows, setCheckedRows] = React.useState([]);
  const [updatedData, getUpdatedData] = React.useState(false);
  const [isMsgStatus, setIsMsgStatus] = React.useState({ status: false, type: "error", message: "" });
  const history = useHistory();
  const statusOrder = {
    "In Progress": 1,
    "Completed": 2,
    "Not Completed": 3,
  };
  //default object for approve or reject the task
  const approveRejectObject = {
    object_type: "answer", //('report', “pricemonitoringreport”, “answer”)
    is_approved: null,
    comment: "",
  };

  //Function for sorting  an array
  const sortDataByStatus = data => {
    if (!data) return;
    return data.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
  };

  useEffect(() => {
    setState({ loading: true });
    let url = `visits/${data.id}/tasks_in_visit/`;

    call({ method: "GET", url }, response => {
      setState({ visits: response, loading: false });
    });
  }, [data, updatedData]);

  const getTaskAnswerStatus = answers => {
    if (!answers) return;

    return answers.boolean_answer
      ? translate(VISIT_PAGE, "task_tab_answer_status_yes_label", me.language)
      : answers.boolean_answer === false
      ? translate(VISIT_PAGE, "task_tab_answer_status_no_label", me.language)
      : null;
  };

  const getType = (item, obj) => {
    if (!item.name) return;

    return (
      <span className="task-type-name" onClick={() => onRowClick(obj)}>
        {item.name}
      </span>
    );
  };

  const getTitle = (title, obj) => {
    if (!title) return;

    return (
      <span className="task-title-name" onClick={() => onRowClick(obj)}>
        {title}
      </span>
    );
  };
  const getDescription = (description, obj) => {
    if (!description) return;

    return (
      <span className="task-title-name" onClick={() => onRowClick(obj)}>
        {description}
      </span>
    );
  };

  const getTaskPriority = (item, obj) => {
    if (!item) return;

    return (
      <p
        className={`visit-task-priority 
     ${
       Number(item.priority) === 0
         ? "required-priority"
         : Number(item.priority) === 1
         ? "high-priority"
         : Number(item.priority) === 2
         ? "medium-priority"
         : Number(item.priority) === 3
         ? "low-priority"
         : ""
     }`}
        onClick={() => onRowClick(obj)}></p>
    );
  };

  const getTaskAnswerText = answer => {
    if (!answer) return;
    return `<p class="visit-task-answer-text">${answer.answer}</p>`;
  };

  const getTaskTags = answers => {
    if (!answers) return;
    let tags = null;

    const setTextTagWrapper = clientTags => {
      return clientTags.tags.map(tag => `<p class="visit-task-tag rounded">${tag}</p>`).join("");
    };

    answers.client_and_tags.forEach(items => {
      tags = items.tags ? setTextTagWrapper(items) : "";
    });
    return tags;
  };

  const getDeadline = (item, obj) => {
    if (!item) return;

    let newDate = new Date(item);
    const currentDate = new Date();
    const date = moment(newDate).format("DD/MM/yyyy");
    return (
      <span className={`visit-task-date ${newDate < currentDate ? "deadline" : ""}`} onClick={() => onRowClick(obj)}>
        {date}
      </span>
    );
  };

  const getTaskStatus = item => {
    if (!item) return;
    const str = item.toLowerCase();
    const result = str.replace(/ /g, "-");
    return `<span class="visit-task-status visit-${result}">${item === "In Progress" ? "On review" : item}</span>`;
  };

  const getTaskRowStatusClass = item => {
    if (!item.status) return;
    const str = item.status.toLowerCase();
    const result = str.replace(/ /g, "-");
    return result;
  };

  const createdBy = (item, obj) => {
    if (!item.id) return;

    return (
      <span className="task-title-name" onClick={() => onRowClick(obj)}>
        {item.first_name + " " + item.last_name?.toLowerCase()}
      </span>
    );
  };

  const onRowClick = task => {
    openSingleVisitTaskRow(task.id, history);
  };

  const handleCheckboxChange = (e, row) => {
    const isChecked = e.target.checked;
    if (!row.answer?.id) {
      setIsMsgStatus({ status: true, type: "error", message: translate(VISIT_PAGE, "task_has_answer_error", me.language) });
      return;
    }

    if (isChecked) {
      setCheckedRows(prev => [...prev, row.answer.id]);
    } else {
      setCheckedRows(prev => prev.filter(id => id !== row.answer.id));
    }
  };

  const prepareData = data => {
    return checkedRows.length === 1 ? data[0] : checkedRows.length > 1 ? data : null;
  };

  const approveTask = () => {
    const data = prepareData(checkedRows);
    const objSize = Array.isArray(data) ? "object_ids" : "object_id";

    approveRejectObject[objSize] = data;
    approveRejectObject.is_approved = true;
    sentAnswer("ACCEPT", approveRejectObject, Array.isArray(data));
  };

  const rejectTask = () => {
    const data = prepareData(checkedRows);
    const objSize = Array.isArray(data) ? "object_ids" : "object_id";

    approveRejectObject[objSize] = data;
    approveRejectObject.is_approved = false;
    sentAnswer("REJECT", approveRejectObject, Array.isArray(data));
  };

  const sentAnswer = (type, body, isArray) => {
    const approveRejectStatus = type === "ACCEPT" ? true : false;
    const isInValid = isArray ? body.object_ids?.length < 1 : !body.object_id;

    if (isInValid) {
      setIsMsgStatus({ status: true, type: "error", message: translate(VISIT_PAGE, "task_has_empty_error", me.language) });
      return;
    }

    const url = isArray ? "approvals/bulk_approval/" : "approvals/";
    call({ method: "POST", url, post: body }, response => {
      if (response) {
        const msg = approveRejectStatus
          ? translate(TRANSLATION_DOWNLOADER, "task_accept_msg", me.language)
          : translate(TRANSLATION_DOWNLOADER, "task_reject_msg", me.language);

        setIsMsgStatus({ status: true, type: "success", message: msg });
        setCheckedRows([]);
        getUpdatedData(!updatedData);
      } else {
        setIsMsgStatus({ status: true, type: "warning", message: JSON.stringify(response) });
        console.log(response);
      }
    });
  };

  const handleSelectAll = () => {
    if (checkedRows.length > 0) {
      setCheckedRows([]);
      return;
    }
    
    const validRowIds = state.visits.filter(row => row.answer?.id).map(row => row.answer.id);
    if (validRowIds.length === 0) setIsMsgStatus({ status: true, type: "error", message: translate(VISIT_PAGE, "task_has_answer_error", me.language) });
       setCheckedRows(validRowIds);
  };

  return (
    <>
      <div className="visit-task-tab-wrapper">
        {isMsgStatus.status && <CreateMsgPopup setIsMsg={setIsMsgStatus} isMsg={isMsgStatus} />}
        {state.visits ? (
          <>
            <div className={`visit-single-btn-wrapper mb-4 ${checkedRows.length > 0 ? "visible" : "hidden"}`}>
              <span>
                {translate(VISIT_PAGE, "monitoring_tab_selected_label", me.language)}: {checkedRows.length}
              </span>
              <button type="button" className="btn btn-default approve-visit" onClick={approveTask}>
                <span></span>
              </button>
              <button type="button" className="btn btn-default reject-visit" onClick={rejectTask}>
                <span>&times;</span>
              </button>
            </div>
            <BootstrapTable
              data={sortDataByStatus(state.visits)}
              version="4"
              striped
              hover
              bordered={false}
              pagination={false}
              search={false}
              multiColumnSearch={true}
              columnFilter={false}
              options={{ ...dataTableConfig }}
              className="themed-main-table-container"
              trClassName={row => `visit-task-tab-item ${getTaskRowStatusClass(row)}`}
              tableHeaderClass="primary">
              <TableHeaderColumn
                dataField="checkbox"
                dataFormat={(cell, row) => (
                  <input type="checkbox" checked={checkedRows.includes(row.answer?.id)} onChange={e => handleCheckboxChange(e, row)} />
                )}
                width="50px">
                <input type="checkbox" checked={checkedRows.length > 0} onChange={handleSelectAll} />
              </TableHeaderColumn>
              <TableHeaderColumn dataField="template" dataFormat={(item, obj) => getTaskPriority(item, obj)} isKey width="60px"></TableHeaderColumn>
              <TableHeaderColumn dataFormat={(item, obj) => getType(item, obj)} dataField="template" width="180px">
                {translate(VISIT_PAGE, "task_tab_type_label", me.language)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField="title" dataFormat={(item, obj) => getTitle(item, obj)} width="280px">
                {translate(VISIT_PAGE, "task_tab_task_name_label", me.language)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField="description" dataFormat={(item, obj) => getDescription(item, obj)} width="220px">
                {translate(VISIT_PAGE, "task_tab_info_label", me.language)}
              </TableHeaderColumn>
              <TableHeaderColumn dataFormat={(item, obj) => createdBy(item, obj)} dataField="created_by" width="200px">
                {translate(VISIT_PAGE, "task_tab_createdby_label", me.language)}
              </TableHeaderColumn>
              <TableHeaderColumn dataFormat={(item, obj) => getDeadline(item, obj)} dataField="end" width="100px">
                {translate(VISIT_PAGE, "task_tab_deadline_label", me.language)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField="image_count" width="146px">
                {translate(VISIT_PAGE, "task_tab_count_photo_label", me.language)}
              </TableHeaderColumn>
              <TableHeaderColumn dataFormat={getTaskStatus} dataField="status" width="120px">
                {translate(VISIT_PAGE, "task_tab_status_label", me.language)}
              </TableHeaderColumn>
              <TableHeaderColumn dataFormat={getTaskAnswerStatus} dataField="answer" width="100px">
                {translate(VISIT_PAGE, "task_tab_answer_status_label", me.language)}
              </TableHeaderColumn>
              <TableHeaderColumn dataFormat={getTaskAnswerText} dataField="answer" width="100px">
                {translate(VISIT_PAGE, "task_tab_answer_comment_label", me.language)}
              </TableHeaderColumn>
              <TableHeaderColumn dataFormat={getTaskTags} dataField="answer" width="100px">
                {translate(VISIT_PAGE, "task_tab_answer_tags_label", me.language)}
              </TableHeaderColumn>
            </BootstrapTable>
          </>
        ) : (
          <Loading loading={state.loading} />
        )}
      </div>
    </>
  );
}

export default Tasks;
