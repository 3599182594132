import React, { useState } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { useHistory } from "react-router-dom";
import Alert from "react-s-alert";

import EntityTable from "../../component/EntityTable";
import { VISIT_PAGE } from "../../config/lang";

import { call } from "../../util/action";
import {
  visitPoint,
  timeSpent,
  authorWay,
  supervisorName,
  approvedBy,
  openVisitRow,
  approveVisitStatus,
  visitItemStatus,
  visitItemProgress,
  visitItemAction,
} from "../../util/formatter";
import { getStore } from "../../util/redux/store";
import { translate } from "../../util/util";

const me = getStore().getState().me;
const List = () => {
  // const history = useHistory();

  const approveVisit = async item => {
    if (item.is_approved) {
      Alert.warning(translate(VISIT_PAGE, "already_approve_visit_msg", me.language), { position: "top-right" });
      return;
    }

    let body = { is_approved: true, approved_by: me.id };
    call({ method: "PATCH", url: "visits/" + item.id + "/", post: body }, r => {
      Alert.success(translate(VISIT_PAGE, "approve_visit_msg", me.language), { position: "top-right" });
      window.location.reload();
    });
  };

  const rejectVisit = item => {
    if (item.is_approved === false) {
      Alert.warning(translate(VISIT_PAGE, "already_reject_visit_msg", me.language), { position: "top-right" });
      return;
    }
    let body = { is_approved: false, approved_by: me.id };

    call({ method: "PATCH", url: "visits/" + item.id + "/", post: body }, r => {
      Alert.success(translate(VISIT_PAGE, "reject_visit_msg", me.language), { position: "top-right" });
      window.location.reload();
    });
  };

  const triggerQuickVisitAction = (item, type) => {
    type === "approve" ? approveVisit(item) : rejectVisit(item);
  };

  //uncomment to open visit row if need open visit in same page and added this fn to init dataFormat on TableHeaderColumn
  // const onRowClick = id => {
  //   openVisitRow(id, history);
  // };

  const rowClassNameFormat = function() {
    return "active-row";
  };

  return (
    <div className="container-fluid visit-page">
      <div className="box p-4">
        <EntityTable entity="visit" rowClassName={rowClassNameFormat}>
          {/* <TableHeaderColumn dataField="id" dataFormat={openVisit} isKey width="120px"></TableHeaderColumn> */}
          <TableHeaderColumn dataFormat={approveVisitStatus} isKey width="37px"></TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={(i, item) => {
              return visitPoint(i, item);
            }}
            dataField="id"
            isKey={true}
            width="280px">
            {translate(VISIT_PAGE, "point", me.language)}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={(i, item) => {
              return supervisorName(i, item);
            }}
            width="220px">
            {translate(VISIT_PAGE, "supervisor", me.language)}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={(i, item) => {
              return approvedBy(i, item);
            }}
            width="220px">
            {translate(VISIT_PAGE, "approved_by", me.language)}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={(i, item) => {
              return authorWay(i, item);
            }}
            width="220px">
            {translate(VISIT_PAGE, "author_way", me.language)}
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={timeSpent} width="120px">
            {translate(VISIT_PAGE, "table_timespent", me.language)}
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={visitItemStatus} width="120px">
            {translate(VISIT_PAGE, "status_label", me.language)}
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={visitItemProgress} width="100px" dataAlign="center">
            {translate(VISIT_PAGE, "progress_label", me.language)}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={(i, item) => {
              return visitItemAction(i, item, triggerQuickVisitAction);
            }}
            width="100px"></TableHeaderColumn>
        </EntityTable>
      </div>
    </div>
  );
};

export default List;
