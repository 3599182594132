import React, { useEffect } from "react";
import moment from "moment";

import { LazyLoading } from "../../../component/general/LazyLoading";
import Loading from "../../../component/Loading";
import Modal from "../../../component/Modal";
import Pagination from "../../../component/Pagination";
import { translatedComponents } from "../../../config/const";
import { TRANSLATION_DOWNLOADER } from "../../../config/lang";
import Preview from "../../../module/photo-review/Preview";

import { call } from "../../../util/action";
import CreateMsgPopup from "../../../util/CreateMsgPopup";
import { getStore } from "../../../util/redux/store";
import { translate, validateReviewTask, urlToIdPhotoAsync, dateChangeFormatFull } from "../../../util/util";

const me = getStore().getState().me;
const defaultTemplate = getStore().getState().defaultTemplate;
function Report({ data }) {
  const [state, setState] = React.useState({
    reports: "",
    loading: false,
    isModalSingle: false,
    isResetPhotoItem: false,
    mode: "list",
    currentIndex: 0,
    data: [],
    count: 0,
    pagination: {
      state: false,
      count: 0,
      sizePerPage: 25,
      pageAmount: 1,
      page: 1,
    },
    lazyPagination: {
      page: 1,
      isNext: false,
    },
    scrollPosition: 0,
    newTask: {},
    newTaskLoading: false,
    isModal: false,
    itemPost: [],
  });
  const [isMsgStatus, setIsMsgStatus] = React.useState({ status: false, type: "error", message: "" });
  const modeTypes = { view: "view", list: "list" };
  const previewNavigationData = {
    currentIndex: state.currentIndex || 0,
    lastIndex: state.reports?.length || 0,
  };
  const editBlockData = () => {
    return {
      item: state.newTask,
      type: "point",
      newTaskFromReviewPhoto: true,
      onChange: (title, property) => newTaskOnChange(title, property),
      onSaveEditorImage: (index, image) => onSaveEditorImage(index, image),
      submit: status => newTaskSubmit(status),
      setOpen: () =>
        setState(prevState => ({
          ...prevState,
          isOpen: true,
        })),
      closeModal: () =>
        setState(prevState => ({
          ...prevState,
          isModal: false,
        })),
      errors: state.errors,
      isImageEditable: true,
      newTaskLoading: state.newTaskLoading,
      isModal: state.isModal,
      currentPhotoIndex: state.quickItemPhotoIndex || 0,
    };
  };

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    let url = `visits/${data.id}/photos_in_visit?page=${state.pagination.page}`;

    call({ method: "GET", url }, response => {
      setState(prevState => ({
        ...prevState,
        reports: response.results,
        loading: false,
        count: response.results.length,
        newTask: setInitTask(),
        lazyPagination: {
          ...prevState.lazyPagination,
          isNext: !!response.results?.length,
        },
      }));
    });
  }, [data]);

  const lazyLoad = () => {
    let { lazyPagination } = state;

    if (!lazyPagination.isNext) return;

    let url = `visits/${data.id}/photos_in_visit?page=${lazyPagination.page + 1}`;

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));
    call({ method: "GET", url }, response => {

      if (!response.results) {
        console.log("error on getting filtered photo list");
        return;
      }

      let isNext = !!response.results.length || false;
      const newPagination = {
        page: response.results.length ? lazyPagination.page + 1 : lazyPagination.page,
        isNext,
      };
      const accumulatedDataList = response.results.length ? [...state.reports, ...response.results] : state.reports;
      setState(prevState => ({
        ...prevState,
        reports: accumulatedDataList,
        loading: false,
        count: state.reports.length + response.results.length,
        lazyPagination: newPagination,
      }));
    });
  };

  const setInitTask = () => ({
    images: [],
    company: null,
    title: "",
    start: new Date(),
    end: new Date(new Date().setHours(23, 59, 59)),
    tags: [],
    components: [],
    priority: 2,
    template: "",
  });

  const newTaskOnChange = (property, value) => {
    let item = state.newTask;

    item[property] = value;
    setState(prevState => ({
      ...prevState,
      newTask: item,
    }));
  };

  const onSaveEditorImage = (index, image) => {
    const { newTask } = state;
    if (!image) return;

    call({ method: "POST", url: "images/", post: { owner: me, image } }, image => {
      if (image?.id) {
        setState(prevState => ({
          ...prevState,
          newTask: { ...newTask, images: [image] },
        }));
      } else {
        console.log(image, "image doesnt have id");
      }
    });
  };

  const openTask = (item, indexPhoto) => {
    const el = document.querySelectorAll(".photo-item-wrapper");
    const rect = el[indexPhoto].getBoundingClientRect();
    
    setState(prevState => ({
      ...prevState,
      item,
      isModalSingle: true,
      currentIndex: indexPhoto,
      scrollPosition: rect.top + window.scrollY - 150,
    }));
  };

  const createNewTask = data => {
    if (!data) return;

    call({ method: "POST", url: "tasks/", post: data }, response => {
      if (response?.id) {
        // Alert.success(translate(TRANSLATION_DOWNLOADER, "newtask_success_msg", me.language));
        setIsMsgStatus({
          status: true,
          type: "success",
          message: translate(TRANSLATION_DOWNLOADER, "newtask_success_msg", me.language),
        });
        setState(prevState => ({
          ...prevState,
          mode: modeTypes.list,
          isModal: false,
          errors: {},
          newTask: setInitTask(),
        }));
        setApproveRejectState("REJECT");
      } else {
        setIsMsgStatus({ status: true, type: "warning", message: "Error while saving item. Error: " + JSON.stringify(response) });
        console.log(response);
      }
    });
  };

  const newTaskSubmit = async status => {
    let { newTask } = state;
    let itemPost = Object.assign({}, newTask);
    let queueLoadingTimer = 0;
    let queueLoadingStep = 8000;

    itemPost.components = newTask.components.map(i => i.id);
    itemPost.network = newTask?.network?.id || null;
    itemPost.end = moment(itemPost.end).format("yyyy-MM-DDT23:59:59");
    itemPost.created_by = newTask.created_by?.id || me?.id || null;
    itemPost.template = itemPost.template === "" ? defaultTemplate : itemPost.template;
    itemPost.visit_id = state.item?.visit_id || 0;

    const { validate, errors } = validateReviewTask("visit-photo", itemPost);
    if (!validate) {
      setState(prevState => ({
        ...prevState,
        errors,
      }));
      return;
    }

    if (itemPost.images.every(image => image.id)) {
      itemPost.images = newTask.images?.map(image => image.id);
      createNewTask(itemPost);
    } else {
      setState(prevState => ({
        ...prevState,
        newTaskLoading: true,
      }));

      itemPost.images = newTask.images?.map((image, index) => {
        if (image.id) {
          itemPost.images = [image.id];
          createNewTask(itemPost);
          setState(prevState => ({
            ...prevState,
            itemPost,
            newTaskLoading: false,
            mode: modeTypes.list,
            isModal: false,
          }));
          return image.id;
        } else {
          setTimeout(args => {
            urlToIdPhotoAsync(image.image, me, createdImg => {
              if (createdImg.id) {
                itemPost.images.push(createdImg.id);
                if (index === newTask.images.length - 1) {
                  itemPost.images = itemPost.images?.filter(item => !!item);
                  createNewTask(itemPost);
                  setState(prevState => ({
                    ...prevState,
                    itemPost,
                    newTaskLoading: false,
                    mode: modeTypes.list,
                    isModal: false,
                  }));
                }
              } else {
                console.error("image was not created");
              }
            });
          }, queueLoadingTimer);
          queueLoadingTimer += queueLoadingStep;
        }
      });
    }
  };

  const moveNext = (DIRECTION = +1) => {
    let { currentIndex, reports, count } = state;
    const el = document.querySelector(".photo-item-wrapper.active");
    const rect = el.getBoundingClientRect();
    let newItem = null;

    // currentIndex = currentIndex + 1;
    if (count === 0) return;

    if (DIRECTION === +1 && currentIndex < count - 1) {
      newItem = reports[currentIndex + 1];
      setState(prevState => ({
        ...prevState,
        currentIndex: prevState.currentIndex + 1,
      }));
    }

    if (DIRECTION === -1 && currentIndex >= 0) {
      newItem = reports[currentIndex - 1];
      setState(prevState => ({
        ...prevState,
        currentIndex: prevState.currentIndex - 1,
      }));
    }

    let newIndex = currentIndex + DIRECTION < count ? currentIndex + DIRECTION : 0;

    if (newIndex === -1 && DIRECTION === -1) newIndex = count - 1;
    if (reports.length === newIndex + 1) {
      lazyLoad();
    }

    if (!reports[currentIndex]) {
      console.log("NEW ITEM NOT EXIST. NewIndex", currentIndex);
      return;
    }

    setState(prevState => ({
      ...prevState,
      item: newItem,
      newTask: setInitTask(),
      isModal: false,
      scrollPosition: rect.top + window.scrollY - 150,
    }));
  };

  const setApproveRejectState = type => {
    const item = state.reports[state.currentIndex];

    if (!item.picsell_info.object_id || !item.picsell_info.object_type) {
      setIsMsgStatus({
        status: true,
        type: "error",
        message: translate(TRANSLATION_DOWNLOADER, "task_object_item_id_msg", me.language),
      });
      return;
    }

    const approveRejectStatus = type === "ACCEPT" ? true : false;
    const approveRejectObject = {
      object_id: item.picsell_info.object_id,
      object_type: item.picsell_info.object_type, //('report', “pricemonitoringreport”, “answer”),
      is_approved: approveRejectStatus,
      comment: "",
    };

    call({ method: "POST", url: "approvals/", post: approveRejectObject }, response => {
      if (response) {
        approveRejectStatus
          ? setIsMsgStatus({
              status: true,
              type: "success",
              message: translate(TRANSLATION_DOWNLOADER, "task_accept_msg", me.language),
            })
          : setIsMsgStatus({
              status: true,
              type: "success",
              message: translate(TRANSLATION_DOWNLOADER, "task_reject_msg", me.language),
            });
        moveNext();
      } else {
        setIsMsgStatus({ status: true, type: "warning", message: "Error: " + type + JSON.stringify(response) });
        console.log(response);
      }
    });
  };

  const passTaskToModal = photoIndex => {
    let { newTask, item } = state;
    newTask.point = item?.visit?.point?.id || null;
    newTask.end = dateChangeFormatFull(newTask.start, 30); // TODO rewrite dateChangeFormat
    newTask.components = [
      { id: 3, name: translatedComponents["photo"], value: 3, label: translatedComponents["photo"] }, // HARDCODE as we dont know photo component object, but it can change
    ];

    if (item.url_photo) {
      newTask.images = [{ image: item.url_photo }];
      newTask.imageFromReview = { image: item.url_photo };
    }

    setState(prevState => ({
      ...prevState,
      newTask,
      isModal: true,
    }));
  };

  const back = () => {
    setState(prevState => ({
      ...prevState,
      mode: modeTypes.list,
    }));

    setTimeout(() => {
      window.scrollTo(0, state.scrollPosition);
    }, 100);
  };

  const modalCloseSinglePhoto = () => {
    setState(prevState => ({
      ...prevState,
      isModalSingle: false,
      loading: false,
    }));
    back();
  };

  return (
    <>
      <div className="visit-report-tab-wrapper">
        {isMsgStatus.status && <CreateMsgPopup setIsMsg={setIsMsgStatus} isMsg={isMsgStatus} />}
        {state.reports
          ? state.reports.map((item, index) => (
              <div className="visit-report-item" key={item.id}>
                <div className={`photo-item-wrapper ${state.currentIndex === index ? "active" : ""}`}>
                  <img src={item.url_photo} alt={item.id} data-img-index={index} onClick={() => openTask(item, index)} />
                  {item.approval && item.approval.is_approved !== null && item.approval.is_approved !== undefined && (
                    <div className="photo-report-item-status">
                      <span className={`${item.approval.is_approved ? "task-approve" : ""}`}>
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className={`${item.approval.is_approved === false ? "task-reject" : ""}`}>
                        <i className="fa fa-times-circle" />
                      </span>
                    </div>
                  )}
                </div>
                <p className="photo-report-item-content">
                  {item.visit.point.address} {item.visit.point.name}
                </p>
              </div>
            ))
          : null}
      </div>
      {state.isModalSingle && (
        <Modal
          isShow={state.isModalSingle}
          isSingle={state.isModalSingle}
          isSupervised={true}
          moveNext={moveNext}
          previewNavigationData={previewNavigationData}
          onClose={modalCloseSinglePhoto}>
          <Preview
            item={state.reports[state.currentIndex]}
            acceptRejectAnswer={setApproveRejectState}
            passTaskToModal={passTaskToModal}
            moveNext={moveNext}
            previewNavigationData={previewNavigationData}
            editBlockData={editBlockData}
            isLoading={state.loading}
            gridViewMode={"single"}
            isResetPhotoItem={false}
          />
        </Modal>
      )}
      <p className="lazy-visit-downloading" style={{ display: state.loading ? "block" : "none" }}>
        {translate(TRANSLATION_DOWNLOADER, "downloading", me?.language ? me?.language : "en")}...
      </p>
      {!state.loading && (
        <>
          <LazyLoading
            loading={false}
            onScroll={async () => {
              lazyLoad();
            }}
          />
        </>
      )}
    </>
  );
}

export default Report;
