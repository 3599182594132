import React, { Component } from "react";

class TaskPriority extends Component {
  renderPriority(priority) {
    if (priority === null || priority === undefined || typeof priority !== "number") {
      return { text: "Unknown", color: "black" };
    }

    const priorityMap = {
      0: { text: "Required", color: "red" },
      1: { text: "High", color: "darkorange" },
      2: { text: "Medium", color: "darkgreen" },
      3: { text: "Low", color: "darkgrey" },
    };

    return priorityMap[priority] || { text: "Unknown", color: "black" };
  }

  render() {
    const { priority } = this.props;
    const { text, color } = this.renderPriority(priority);

    return <p style={{ color, fontWeight: "bold" }}>{text}</p>;
  }
}

export default TaskPriority;
