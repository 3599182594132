import React, { useEffect } from "react";

function CreateMsgPopup({ setIsMsg, isMsg }) {
  useEffect(() => {
    setTimeout(() => {
      setIsMsg({ status: false, message: "" });
    }, 1500);
  }, []);

  return (
    <>
      <div
        className={`custom-error-block s-alert-box s-alert-top s-alert-show s-alert-${
          isMsg.type === "success" ? "success" : isMsg.type === "error" ? "error" : "warning"
        }`}>
        <div className="s-alert-box-inner">{isMsg.message}</div>
      </div>
    </>
  );
}

export default CreateMsgPopup;
